@import "additional-styles/utility-patterns.css";
@import "additional-styles/theme.css";
@import "vendors/aos.css";

@import url("https://fonts.googleapis.com/css?family=Caveat:regular,bold,italic&subset=latin,latin-ext");
@import url("https://fonts.googleapis.com/css2?family=Inter&family=Righteous&display=swap");

.bg-slate-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(147 51 234 / var(--tw-bg-opacity));
}

.text-slate-200 {
  --tw-text-opacity: 1;
  color: rgb(226 232 240 / var(--tw-text-opacity));
}

input.text-lg {
  font-size: 1.125rem !important; /* 18px */
  line-height: 1.75rem; /* 28px */
}

#root {
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.md\:block {
  display: block;
}
.blur-2xl {
  --tw-blur: blur(40px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
    var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate)
    var(--tw-sepia) var(--tw-drop-shadow);
}
.opacity-70 {
  opacity: 0.7;
}
.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-mt-36 {
  margin-top: -9rem;
}
.-z-10 {
  z-index: -10;
}
.left-1\/2 {
  left: 50%;
}
.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.max-w-none {
  max-width: none;
}
img,
video {
  height: auto;
  max-width: none;
}

video {
  object-fit: cover;
  --tw-blur: blur(10px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
    var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate)
    var(--tw-sepia) var(--tw-drop-shadow);
  z-index: -1;
}

.bg-video {
  position: absolute;
  min-width: 100vw;
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bg-video__content {
  position: absolute;
  min-height: 100vh;
  min-width: 100vw;
  height: 110%;
}

body {
  color: #555;
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Inter;
}

.App {
  text-align: center;
}

h1 {
  font-family: Caveat;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

.App-link {
  color: #61dafb;
}

.debugging-iframe {
  display:none;
  position: absolute;
  width: 100%;
  bottom: 0px;
  height: 300px;
  left: 0px;
  border:1px solid #fff;
  background-color:#fff;
}

.plane-loading-container {
  display: flex;
  height: 300px;
  width: 300px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  animation: SunEffect 10s ease-in-out reverse infinite;
  border-radius: 8em 8em 1em 1em;
  border: 1em solid rgba(223, 166, 172, 0.23);
}

.plane-loading-container svg {
  width: 100%;
  height: 100%;
  fill: rgba(223, 166, 172, 0.43);
}

.cloud {
  height: 90px;
  animation: float 12s ease-in reverse infinite;
}

.cloud2 {
  height: 70px;
  animation: float 8s ease-in-out infinite;
}

.cloud3 {
  height: 60px;
  animation: float 5s ease-in reverse infinite;
}

.plane {
  height: 100px;
  animation: sway 2s ease-in infinite;
}

@keyframes sway {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes float {
  0% {
    transform: translateX(-180px);
  }
  100% {
    transform: translateX(480px);
  }
}

@keyframes SunEffect {
  0%,
  100% {
    box-shadow: 5px -5px 46px 0px rgba(255, 128, 170, 0.5),
      5px -5px 44px -21px rgba(69, 28, 111, 0.8),
      5px -5px 44px -21px rgba(86, 40, 126, 0.9),
      5px -5px 44px -21px rgba(31, 16, 49, 0.7),
      5px -5px 124px -35px rgba(219, 110, 154, 0.4),
      5px -5px 5px 0px rgba(158, 45, 142, 1),
      15px 10px 38px -8px rgba(240, 180, 40, 0.15),
      15px 20px 38px -8px rgba(220, 170, 175, 0.25),
      10px -5px 28px 6px rgba(244, 75, 102, 0.45);
  }

  10% {
    box-shadow: 4px 4px 47px 0.5px rgba(255, 134, 172, 0.3),
      4.5px 4.5px 44.5px -20.5px rgba(69, 24.5, 105.5, 1),
      4.5px 4.5px 44.5px -20.5px rgba(83.5, 37.5, 121, 1),
      4.5px 4.5px 44.5px -20.5px rgba(30, 13.5, 44.5, 1),
      4.5px 4.5px 123px -34.5px rgba(217.5, 109.5, 154.5, 0.32),
      4.5px 4.5px 5.5px 0.5px rgba(158.5, 64, 139.5, 1),
      14.5px 11px 36.5px -8.5px rgba(241.5, 176.5, 38, 0.13.5),
      14.5px 21px 36.5px -8.5px rgba(222.5, 165.5, 171.5, 0.23),
      9.5px 4.5px 27.5px 5.5px rgba(243.5, 69.5, 96, 0.41);
  }
  25% {
    box-shadow: 4px -4px 46px 1px rgba(255, 133, 173, 0.55),
      4px -4px 43px -20px rgba(72, 31, 116, 0.85),
      4px -4px 43px -20px rgba(89, 43, 130, 0.95),
      4px -4px 43px -20px rgba(33, 18, 52, 0.75),
      4px -4px 123px -34px rgba(221, 113, 157, 0.42),
      4px -4px 5px 1px rgba(159, 48, 145, 1),
      14px 11px 37px -7px rgba(238, 182, 42, 0.18),
      14px 21px 37px -7px rgba(217, 172, 178, 0.28),
      9px -4px 27px 7px rgba(245, 78, 105, 0.48);
  }
  35% {
    box-shadow: 2px 1px 49px 3.5px rgba(255, 145, 177.5, 0.5),
      3px 2px 46.5px -19.5px rgba(71, 25.5, 107, 1),
      3px 2px 46.5px -19.5px rgba(78, 34.5, 118.5, 1),
      3px 2px 46.5px -19.5px rgba(29.5, 12.5, 43.5, 1),
      3px 2px 121.5px -33.5px rgba(213, 107, 157, 0.33),
      3px 2px 6.5px 1.5px rgba(159.5, 62, 139, 1),
      13.5px 13px 36px -7.5px rgba(239.5, 174, 36.5, 0.15),
      13.5px 23px 36px -7.5px rgba(219, 163, 169.5, 0.24),
      8.5px 1.5px 28.5px 6.5px rgba(242.5, 67.5, 95, 0.42);
  }
  50% {
    box-shadow: 3px -3px 45px 2px rgba(255, 138, 176, 0.6),
      3px -3px 42px -19px rgba(75, 34, 121, 0.9),
      3px -3px 42px -19px rgba(92, 46, 134, 1),
      3px -3px 42px -19px rgba(35, 20, 55, 0.8),
      3px -3px 122px -33px rgba(223, 116, 160, 0.45),
      3px -3px 4px 2px rgba(160, 51, 148, 1),
      13px 12px 36px -6px rgba(236, 184, 44, 0.2),
      13px 22px 36px -6px rgba(214, 174, 181, 0.3),
      8px -3px 26px 8px rgba(246, 81, 108, 0.5);
  }
  65% {
    box-shadow: 2px -1px 49px 3.5px rgba(255, 140, 172.5, 0.4),
      3px -2px 46.5px -19.5px rgba(68, 23.5, 106, 1),
      3px -2px 46.5px -19.5px rgba(76, 33.5, 119.5, 1),
      3px -2px 46.5px -19.5px rgba(29.5, 12.5, 43.5, 1),
      3px -2px 120.5px -33.5px rgba(210.5, 106.5, 151.5, 0.32),
      3px -2px 6.5px 0.5px rgba(158.5, 61, 138, 1),
      13.5px 9px 35.5px -8.5px rgba(238.5, 175.5, 36, 0.14),
      13.5px 19px 35.5px -8.5px rgba(218, 162, 168.5, 0.23),
      8.5px -2.5px 27.5px 5.5px rgba(240.5, 66.5, 93, 0.41);
  }
  75% {
    box-shadow: 4px -4px 46px 1px rgba(255, 133, 173, 0.55),
      4px -4px 43px -20px rgba(72, 31, 116, 0.85),
      4px -4px 43px -20px rgba(89, 43, 130, 0.95),
      4px -4px 43px -20px rgba(33, 18, 52, 0.75),
      4px -4px 123px -34px rgba(221, 113, 157, 0.42),
      4px -4px 5px 1px rgba(159, 48, 145, 1),
      14px 11px 37px -7px rgba(238, 182, 42, 0.18),
      14px 21px 37px -7px rgba(217, 172, 178, 0.28),
      9px -4px 27px 7px rgba(245, 78, 105, 0.48);
  }
  85% {
    box-shadow: 4px -4px 48px 1.5px rgba(255, 130, 167, 0.25),
      4.5px -4.5px 44.5px -20.5px rgba(68, 23.5, 104.5, 1),
      4.5px -4.5px 44.5px -20.5px rgba(76, 33.5, 118.5, 1),
      4.5px -4.5px 44.5px -20.5px rgba(29.5, 12.5, 43.5, 1),
      4.5px -4.5px 122px -32.5px rgba(209, 107.5, 152.5, 0.31),
      4.5px -4.5px 5.5px -0.5px rgba(157, 60.5, 137, 1),
      14.5px 7px 34.5px -8.5px rgba(238, 172.5, 35.5, 0.13.5),
      14.5px 17px 34.5px -8.5px rgba(217.5, 161.5, 167.5, 0.22),
      9.5px -4px 27.5px 5.5px rgba(240, 66, 92, 0.4);
  }
}

.core-start-button { height: 45px; }
.main-results-open { background-color: #120b1f; }
.upperConfigurationSection > div:first-child {
}
@media (max-width: 20em) {
  :root {
    min-width: 18em;
  }
  body {min-width: 18em;}
  .bg-video {min-width: 18em}
}
@media (max-width: 30em) {
  
}
@media (max-width: 48em) {
  
}
@media (max-width: 62em) {
  
}
@media (min-width: 75em) {
  
}